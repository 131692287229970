import { Conversation } from "@twilio/conversations";

const getUpdatedStatus = async (status: string | undefined, conversation: Conversation) => {
    if (!status) {
        return undefined;
    }
    if (status !== "closed") {
        return status;
    }

    const attributes = await conversation.getAttributes();

    if (attributes?.newTaskSidSurvey && attributes?.sendToSurveySid) {
        return "survey";
    }

    return "closed";
};

export default getUpdatedStatus;
