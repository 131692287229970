import { BoxStyleProps } from "@twilio-paste/core/box";

export const getContainerStyles = (isMobile: boolean): BoxStyleProps => {
    return {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: "colorBackgroundPrimary",
        borderBottomLeftRadius: "borderRadius30",
        borderBottomRightRadius: "borderRadius30",
        alignContent: "space-around",
        textAlign: "center",
        color: "colorTextIconBrandInverse",
        paddingTop: "space40",
        paddingBottom: "space20",
        ...(isMobile && {
            borderRadius: "borderRadius0"
        })
    };
};
