import { Question } from "./survey.entities";
import { GetQuestionsApiResponse } from "./survey.entities.api";

export function toQuestionList(apiResponse: GetQuestionsApiResponse): Question[] {
    if (!apiResponse.enabled) {
        return [];
    }

    return apiResponse.survey.map((question) => ({
        text: question.ask,
        availableAnswers: question.answer_emojis,
        answer: null
    }));
}
