import { Media, Message } from "@twilio/conversations";
import { Box } from "@twilio-paste/core/box";
import { useSelector } from "react-redux";
import { Text } from "@twilio-paste/core/text";
import { Flex } from "@twilio-paste/core/flex";
import { UserIcon } from "@twilio-paste/icons/esm/UserIcon";
import { Key, KeyboardEvent, useEffect, useRef, useState } from "react";
import { SuccessIcon } from "@twilio-paste/icons/esm/SuccessIcon";
import log from "loglevel";

import { AppState } from "../store/definitions";
import { FilePreview } from "./FilePreview";
import { parseMessageBody } from "../utils/parseMessageBody";
import {
    getAvatarContainerStyles,
    getInnerContainerStyles,
    bodyStyles,
    outerContainerStyles,
    readStatusStyles,
    bubbleAndAvatarContainerStyles
} from "./styles/MessageBubble.styles";
import { useTranslation } from "../hooks/useTranslation";
import { PREFIX_PROACTIVE_SUPPORT } from "../constants";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { KeysLocalStorage } from "../definitions";

export const MessageBubble = ({
    message,
    isLast,
    isLastOfUserGroup,
    focusable,
    updateFocus,
    isUser
}: {
    message: Message;
    isLast: boolean;
    isLastOfUserGroup: boolean;
    focusable: boolean;
    updateFocus: (newFocus: number) => void;
    isUser?: boolean;
}) => {
    const [read, setRead] = useState(false);
    const { i18n } = useTranslation();
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [messagesDisabled, setMessagesDisabled] = useLocalStorage<Record<string, boolean>>(
        KeysLocalStorage.MESSAGES_DISABLED,
        {}
    );

    const { conversationsClient, conversation, participants, fileAttachmentConfig } = useSelector(
        (state: AppState) => ({
            conversationsClient: state.chat.conversationsClient,
            conversation: state.chat.conversation,
            participants: state.chat.participants,
            users: state.chat.users,
            fileAttachmentConfig: state.config.fileAttachment
        })
    );
    const messageRef = useRef<HTMLDivElement>(null);

    const isOutboundMessage = message.body.includes(PREFIX_PROACTIVE_SUPPORT);

    const attributes = message.attributes as {
        buttonOptions?: string[];
        disabled?: boolean;
    };

    const idMessage = message.sid.toString();
    const buttonOptions = attributes?.buttonOptions;
    const messageDisabled = messagesDisabled?.[idMessage] || attributes?.disabled;

    const belongsToCurrentUser =
        isUser || (message.author === conversationsClient?.user.identity && !isOutboundMessage);

    useEffect(() => {
        if (isLast && participants && belongsToCurrentUser && !isOutboundMessage) {
            const getOtherParticipants = participants.filter((p) => p.identity !== conversationsClient?.user.identity);
            setRead(
                Boolean(getOtherParticipants.length) &&
                    getOtherParticipants.every((p) => p.lastReadMessageIndex === message.index)
            );
        } else {
            setRead(false);
        }
    }, [participants, isLast, belongsToCurrentUser, conversationsClient, isOutboundMessage, message]);

    useEffect(() => {
        if (focusable) {
            messageRef.current?.focus();
        }
    }, [focusable]);

    const renderMedia = () => {
        if (fileAttachmentConfig?.enabled) {
            if (!message.attachedMedia) {
                return null;
            }

            return message.attachedMedia.map((media: Media, index: Key) => {
                const file = {
                    name: media.filename,
                    type: media.contentType,
                    size: media.size
                } as File;
                return <FilePreview key={index} file={file} isBubble={true} media={media} focusable={focusable} />;
            });
        }

        return <i>{i18n.messagingAreNotSupport}</i>;
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            const newFocusValue = message.index + (e.key === "ArrowUp" ? -1 : 1);
            updateFocus(newFocusValue);
        }
    };

    const handleMouseDown = () => {
        setIsMouseDown(true);
    };

    const handleMouseUp = () => {
        setIsMouseDown(false);
    };

    const handleFocus = () => {
        // Ignore focus from clicks
        if (!isMouseDown) {
            // Necessary since screen readers can set the focus to any focusable element
            updateFocus(message.index);
        }
    };

    const handleOnButton = (e: { currentTarget: { style: { backgroundColor: string; opacity: string } } }) => {
        e.currentTarget.style.backgroundColor = "rgb(31 135 240 / 0%)";
        e.currentTarget.style.opacity = "0.7";
    };
    const handleOutBlurButton = (e: { currentTarget: { style: { backgroundColor: string; opacity: string } } }) => {
        e.currentTarget.style.backgroundColor = "rgb(31 135 240 / 1%)";
        e.currentTarget.style.opacity = "1";
    };

    const isNote = message.type !== "media" && !message.body;
    if (isNote) return null;

    return (
        <Box
            {...outerContainerStyles}
            tabIndex={focusable ? 0 : -1}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            ref={messageRef}
            data-message-bubble
            data-testid="message-bubble"
        >
            <Box {...bubbleAndAvatarContainerStyles}>
                {!belongsToCurrentUser && (
                    <Box {...getAvatarContainerStyles(!isLastOfUserGroup)} data-testid="avatar-container">
                        {isLastOfUserGroup && <UserIcon decorative={true} size="sizeIcon40" />}
                    </Box>
                )}
                <Box {...getInnerContainerStyles(belongsToCurrentUser)}>
                    <Text as="p" {...bodyStyles}>
                        {message.body
                            ? parseMessageBody(message.body.replace(PREFIX_PROACTIVE_SUPPORT, ""), belongsToCurrentUser)
                            : null}
                    </Text>
                    {message.type === "media" ? renderMedia() : null}

                    {buttonOptions && (
                        <Box display="flex" flexDirection="column" rowGap="space20" marginTop="space80">
                            {buttonOptions.map((option, index) => (
                                <button
                                    disabled={messageDisabled}
                                    type="button"
                                    key={index}
                                    style={{
                                        border: "1px solid #0121",
                                        borderRadius: "10px",
                                        padding: "8px 12px",
                                        fontSize: "14px",
                                        cursor: "pointer",
                                        outline: "none",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        background: "rgb(31 135 240 / 1%)",
                                        transition: "background 0.2s ease"
                                    }}
                                    onFocus={handleOnButton}
                                    onBlur={handleOutBlurButton}
                                    onMouseEnter={handleOnButton}
                                    onMouseLeave={handleOutBlurButton}
                                    onClick={async () => {
                                        if (!conversation) {
                                            log.error("Failed sending message: no conversation found");
                                            return;
                                        }

                                        setMessagesDisabled({
                                            ...messagesDisabled,
                                            [idMessage]: true
                                        });
                                        let preparedMessage = conversation.prepareMessage();
                                        preparedMessage = preparedMessage.setBody(option);
                                        preparedMessage.build().send();
                                    }}
                                >
                                    <Text as="p" {...bodyStyles}>
                                        {option}
                                    </Text>
                                </button>
                            ))}
                        </Box>
                    )}
                </Box>
            </Box>
            {read && (
                <Flex hAlignContent="right" vAlignContent="center" marginTop="space20">
                    <Text as="p" {...readStatusStyles}>
                        {i18n.messagingRead}
                    </Text>
                    <SuccessIcon decorative={true} size="sizeIcon10" color="colorTextWeak" />
                </Flex>
            )}
        </Box>
    );
};
