/* eslint-disable no-nested-ternary */
import { useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "@twilio-paste/core";

import { Header } from "./Header";
import { Footer } from "./Footer";
import { MessageList } from "./MessageList";
import { MessageInput } from "./MessageInput";
import { AppState } from "../store/definitions";
import { NotificationBar } from "./NotificationBar";
import { removeNotification } from "../store/actions/genericActions";
import { AttachFileDropArea } from "./AttachFileDropArea";
import { useNotifications } from "../hooks/useNotifications";
import { useChatHistory } from "../hooks/useChatHistory";
import ConversationItem from "./ConversationItem";
import { MessageListStatic } from "./MessageListStatic";
import NoMessages from "./NoMessages";
import Home from "./Home";
import ConversationSurvey from "./ConversationSurvey";

export const MessagingCanvasPhase = () => {
    const dispatch = useDispatch();
    const notifications = useNotifications();
    const chatState = useSelector(
        ({ chat: { conversationState, chatRoute, closedChats, selectedConversationId } }: AppState) => ({
            conversationState,
            chatRoute,
            closedChats,
            selectedConversationId
        })
    );
    const { conversationState, chatRoute, closedChats, selectedConversationId } = chatState;
    useChatHistory();

    useEffect(() => {
        dispatch(removeNotification(notifications.failedToInitSessionNotification("ds").id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const Wrapper = conversationState === "active" ? AttachFileDropArea : Fragment;

    switch (chatRoute) {
        case "closed":
            return (
                <Wrapper>
                    <Header />
                    <NotificationBar />
                    <div
                        style={{
                            display: closedChats && closedChats.length > 0 ? undefined : "flex",
                            flex: 1,
                            flexGrow: 1,
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            overflowY: "scroll"
                        }}
                    >
                        {selectedConversationId ? (
                            <MessageListStatic
                                messages={
                                    closedChats?.find((chat) => chat.conversation.sid === selectedConversationId)
                                        ?.messages || []
                                }
                            />
                        ) : closedChats === undefined ? (
                            <Spinner decorative={false} title="Loading" size="sizeIcon80" />
                        ) : closedChats.length === 0 ? (
                            <NoMessages />
                        ) : (
                            closedChats.map((chat) => (
                                <ConversationItem
                                    participants={chat.participants || []}
                                    key={chat.conversation.sid}
                                    lastMessage={
                                        chat.messages && chat.messages.length > 0
                                            ? chat.messages[chat.messages.length - 1].body
                                            : "..."
                                    }
                                    updatedAt={chat.conversation.updated_at}
                                    onClick={() => {
                                        dispatch({
                                            type: "ACTION_SELECT_CONVERSATION",
                                            payload: chat.conversation.sid
                                        });
                                    }}
                                />
                            ))
                        )}
                    </div>
                    {!selectedConversationId && <Footer />}
                </Wrapper>
            );
        default:
        case "active":
            return (
                <Wrapper>
                    <Header />
                    <NotificationBar />
                    {Boolean(selectedConversationId) ? (
                        <>
                            <MessageList />
                            {conversationState === "survey" ? (
                                <ConversationSurvey />
                            ) : (
                                <div style={{ padding: 10 }}>
                                    <MessageInput />
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <Home />
                            <Footer />
                        </>
                    )}
                </Wrapper>
            );
    }
};
