import { Box, ButtonProps } from "@twilio-paste/core";
import { Button } from "@twilio-paste/core/button";
import { ButtonGroup } from "@twilio-paste/button-group";

import { Question } from "../modules/surveys/survey.entities";

interface SurveyQuestionProps {
    question: Question;
    handleAnswer: (answer: string) => void;
}

const SurveyButton = (props: ButtonProps) => {
    return <Button {...props} element="SURVEY_BUTTON" />;
};

export const SurveyQuestion = ({ question, handleAnswer }: SurveyQuestionProps) => {
    return (
        <>
            <Box fontWeight="fontWeightSemibold" marginBottom="space40">
                {question.text}
            </Box>
            <Box width="100%" marginBottom="space50">
                <ButtonGroup>
                    {question.availableAnswers.map((option, index) => (
                        <SurveyButton key={index} variant="secondary_icon" onClick={() => handleAnswer(option.title)}>
                            {option.emoji}
                        </SurveyButton>
                    ))}
                </ButtonGroup>
            </Box>
        </>
    );
};
