import log from "loglevel";
import { Conversation } from "@twilio/conversations";

import { getQuestions, submitSurvey } from "../modules/surveys/surveys.repository";

export const fetchSurvey = async (conversation: Conversation | undefined, token: string | undefined) => {
    if (!conversation || !token) {
        return {
            questions: []
        };
    }

    const questions = await getQuestions({
        conversationSid: conversation.sid,
        flowSid: conversation.attributes.sendToSurveySid,
        token
    });
    return { questions };
};

export const saveAnswers = async (
    conversation: Conversation | undefined,
    token: string | undefined,
    answers: string[]
): Promise<boolean> => {
    if (!conversation || !token) {
        return false;
    }

    try {
        await submitSurvey({
            conversationSid: conversation.sid,
            token,
            answers
        });
    } catch (error) {
        log.error(error);
        return false;
    }

    return true;
};
