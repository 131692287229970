import { toQuestionList } from "./survey.adapters";
import { GetQuestionsApiResponse } from "./survey.entities.api";

interface GetQuestionsParams {
    conversationSid: string;
    flowSid: string;
    token: string;
}

interface SubmitSurveyParams {
    conversationSid: string;
    token: string;
    answers: string[];
}

export async function getQuestions({ conversationSid, flowSid, token }: GetQuestionsParams) {
    const payload = {
        conversationSid,
        flowSid,
        Token: token
    };

    const response = await fetch(`${process.env.FLEX_EXTENSIONS_URL}/features/survey-webchat/fetch-statusV2`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
    });

    if (!response.ok) {
        throw new Error("Failed to fetch responses");
    }
    const apiQuestions: GetQuestionsApiResponse = await response.json();

    return toQuestionList(apiQuestions);
}

export async function submitSurvey({ conversationSid, token, answers }: SubmitSurveyParams) {
    if (!conversationSid || !token) {
        throw new Error("Missing conversationSid or token");
    }

    if (answers.length === 0) {
        return;
    }

    const payload = {
        conversationSid,
        response1: answers[0],
        Token: token
    };

    const response = await fetch(`${process.env.FLEX_EXTENSIONS_URL}/features/survey-webchat/flex/survey-response`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
    });

    if (!response.ok) {
        throw new Error("Failed to fetch responses");
    }
}
