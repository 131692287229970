import { Conversation } from "@twilio/conversations";
import { Dispatch } from "redux";

import { ACTION_UPDATE_CONVERSATION_STATE } from "../actionTypes";
import getUpdatedStatus from "../../../modules/surveys/getUpdatedStatus";

export const initConversationListener = (conversation: Conversation, dispatch: Dispatch) => {
    conversation.addListener("updated", async ({ conversation: updatedConversation, updateReasons }) => {
        // we are listening only to a subset of events.
        if (updateReasons?.includes("state")) {
            const updatedState = await getUpdatedStatus(updatedConversation?.state?.current, updatedConversation);
            dispatch({
                type: ACTION_UPDATE_CONVERSATION_STATE,
                payload: { conversationState: updatedState }
            });
        }
    });
};
