import { useDispatch, useSelector } from "react-redux";
import { CustomizationProvider, CustomizationProviderProps } from "@twilio-paste/core/customization";
import { CSSProperties, FC, useEffect } from "react";
import { Client } from "@twilio/conversations";

import { RootContainer } from "./RootContainer";
import { AppState, EngagementPhase } from "../store/definitions";
import { sessionDataHandler } from "../sessionDataHandler";
import { initSession } from "../store/actions/initActions";
import { changeEngagementPhase } from "../store/actions/genericActions";
import { useNotifications } from "../hooks/useNotifications";
import { useProactiveSupportMessage } from "../hooks/useProactiveSupportMessage";
import useCampaignInterceptor from "../hooks/useCampaignInterceptor";

const AnyCustomizationProvider: FC<CustomizationProviderProps & { style: CSSProperties }> = CustomizationProvider;

export function WebchatWidget() {
    useCampaignInterceptor();
    useProactiveSupportMessage();
    const theme = useSelector((state: AppState) => state.config.theme);
    const notifications = useNotifications();
    const dispatch = useDispatch();

    useEffect(() => {
        const resume = async () => {
            const data = await sessionDataHandler.tryResumeExistingSession();
            try {
                if (!data) throw new Error("No token found");
                const conversationsClient = await Client.create(data.token);
                const conversation = await conversationsClient.getConversationBySid(data.conversationSid);
                if (conversation.state?.current === "closed") {
                    throw new Error("Conversation is closed");
                }
                dispatch(initSession({ token: data.token, conversationSid: data.conversationSid, notifications }));
            } catch (e) {
                // if initSession fails, go to changeEngagement phase - most likely there's something wrong with the store token or conversation sis
                dispatch(changeEngagementPhase({ phase: EngagementPhase.PreEngagementForm }));
            }
        };
        resume();
    }, [dispatch, notifications]);

    return (
        <AnyCustomizationProvider
            baseTheme={theme?.isLight ? "default" : "dark"}
            theme={theme?.overrides}
            elements={{
                MESSAGE_INPUT: {
                    boxShadow: "none!important" as "none"
                },
                MESSAGE_INPUT_BOX: {
                    display: "inline-block",
                    boxShadow: "none"
                },
                ALERT: {
                    paddingTop: "space30",
                    paddingBottom: "space30"
                },
                BUTTON: {
                    "&[aria-disabled='true'][color='colorTextLink']": {
                        color: "colorTextLinkWeak"
                    }
                },
                TOOLTIP: {
                    zIndex: "zIndex90"
                },
                SURVEY_BUTTON: {
                    fontSize: "fontSize80"
                }
            }}
            style={{ minHeight: "100%", minWidth: "100%" }}
        >
            <RootContainer />
        </AnyCustomizationProvider>
    );
}
