import { Client, Conversation, Participant, Message, User, Media } from "@twilio/conversations";
import { GenericThemeShape } from "@twilio-paste/theme";
import { AlertVariants } from "@twilio-paste/core/alert";

import { Brand, FileAttachmentConfig, Locale, TranscriptConfig } from "../definitions";
import { ProactiveSupportMessage } from "../modules/proactiveSupport/proactiveSupport.entities";
import { ClosedChat } from "../modules/chatHistory/chatHistory.entities";

export enum EngagementPhase {
    PreEngagementForm = "PreEngagementForm",
    MessagingCanvas = "MessagingCanvas",
    PreviewProactiveSupportMessage = "PreviewProactiveSupportMessage",
    Loading = "Loading"
}

// eslint-disable-next-line import/no-unused-modules
export type ProactiveSupport = {
    messages: ProactiveSupportMessage[];
    currentEventTrigger?: string;
    currentTextVersionId?: string;
    currentMessageId?: string;
};

export type ChatStateBase = {
    participants?: Participant[];
    users?: User[];
    messages?: Message[];
    attachedFiles?: File[];
    previewMedia?: Media;
    conversationState?: string;
};

export type ChatState = ChatStateBase & {
    conversationsClient?: Client;
    conversation?: Conversation;
    inputMessage?: string;
    chatRoute: "active" | "closed";
    closedChats?: ClosedChat[];
    selectedConversationId?: string;
    proactiveMessageId?: string | null;
};

export type PreEngagementData = { name: string; email: string; query: string };

export type SessionState = {
    currentPhase: EngagementPhase;
    expanded: boolean;
    token?: string;
    conversationSid?: string;
    conversationsClient?: Client;
    conversation?: Conversation;
    users?: User[];
    participants?: Participant[];
    messages?: Message[];
    conversationState?: "active" | "inactive" | "closed" | "survey";
    preEngagementData?: PreEngagementData;
};

export type ThemeOverride = {
    isLight?: boolean;
    overrides?: Partial<GenericThemeShape>;
};

export type ConfigState = {
    brand?: Brand;
    locale?: Locale;
    posProfile?: string;
    serverUrl?: string;
    hideChatBubble?: boolean;
    disableProactiveSupport?: boolean;
    theme?: ThemeOverride;
    fileAttachment?: FileAttachmentConfig;
    transcript?: TranscriptConfig;
};

export type Notification = {
    dismissible: boolean;
    id: string;
    onDismiss?: () => void;
    message: string;
    timeout?: number;
    type: AlertVariants;
};

export type NotificationState = Notification[];

export type AppState = {
    chat: ChatState;
    config: ConfigState;
    session: SessionState;
    notifications: NotificationState;
    proactiveSupport?: ProactiveSupport;
};

type MapActionPayload<M extends { [index: string]: unknown }> = {
    [Key in keyof M]: M[Key] extends undefined
        ? {
              type: Key;
          }
        : {
              type: Key;
              payload: M[Key];
          };
};

export type ActionPayloadType<Payload extends Record<string, unknown>> =
    MapActionPayload<Payload>[keyof MapActionPayload<Payload>];
