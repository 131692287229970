import log from "loglevel";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Spinner } from "@twilio-paste/core";

import { ACTION_UPDATE_CONVERSATION_STATE } from "../store/actions/actionTypes";
import { AppState } from "../store/definitions";
import { fetchSurvey, saveAnswers } from "../hooks/useSurvey";
import { Question } from "../modules/surveys/survey.entities";
import { SurveyQuestion } from "./SurveyQuestion";
import { getContainerStyles } from "./styles/Survey.styles";
import { useDevice } from "../hooks/useDevice";
import { useTranslation } from "../hooks/useTranslation";

const ConversationSurvey = () => {
    const conversation = useSelector(({ chat }: AppState) => chat.conversation);
    const token = useSelector(({ session }: AppState) => session.token);
    const [question, setQuestion] = useState<Question | null>(null);
    const [isAnswered, setIsAnswered] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { isMobile } = useDevice();
    const { i18n } = useTranslation();

    useEffect(() => {
        fetchSurvey(conversation, token).then(({ questions: qs }) => {
            if (qs.length === 0) {
                log.error("No survey questions found");
                dispatch({
                    type: ACTION_UPDATE_CONVERSATION_STATE,
                    payload: { conversationState: "closed" }
                });
                return;
            }
            setQuestion(qs[0]);
        });
        /*
         * intentionally not including dispatch in the dependencies array
         * to trigger the effect only once
         */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveAnswer = (answer: string) => {
        saveAnswers(conversation, token, [answer]);
        setIsAnswered(true);
    };

    const goBack = () => {
        dispatch({
            type: ACTION_UPDATE_CONVERSATION_STATE,
            payload: { conversationState: "closed" }
        });
    };

    return question ? (
        <Box {...getContainerStyles(isMobile)}>
            {isAnswered ? (
                <>
                    <Box paddingBottom="space60" fontWeight="fontWeightSemibold">
                        {i18n.surveyThanksForAnswering}{" "}
                    </Box>
                    <Box paddingLeft="space40" paddingRight="space40" paddingBottom="space40">
                        <Button variant="secondary" onClick={goBack}>
                            {i18n.surveyBack}
                        </Button>
                    </Box>
                </>
            ) : (
                <SurveyQuestion question={question} handleAnswer={saveAnswer} />
            )}
        </Box>
    ) : (
        <Spinner decorative={false} title="Loading" />
    );
};

export default ConversationSurvey;
